import React from "react"
import ScrollableAnchor from "react-scrollable-anchor"

import PlaylistItem from "./playlist-item"

class PlaylistGroup extends React.Component {

    render(){

        const { featured, items } = this.props

        return(
            <section className={"my-16"}>
                <div className={"container mx-auto md:px-8 lg:px-8"}>
                    <ScrollableAnchor id={featured[0].playlist.slug}>
                        <PlaylistItem {...featured[0].playlist} />
                    </ScrollableAnchor>
                    {
                        items.map((item,index) => {
                            return (
                                <ScrollableAnchor id={item.playlist.slug} key={`playlist-${index}`}>
                                    <PlaylistItem {...item.playlist}  />
                                </ScrollableAnchor>
                            )
                        })
                    }
                
                </div>
            </section>
        )
    }
}

export default PlaylistGroup