import React from "react"

import Button from "../button/button"

import Styles from "./playlist.module.scss"

class PlaylistItem extends React.Component {

    render(){

        const { title, short, artistLink, artistEmbed, description, playlistLink} = this.props

        return(
            
                <div className={"mb-12"}>
                    <div className={"flex flex-wrap mb-12"}>
                        <div className={"w-full mx-auto px-4 mb-8 md:w-1/2 md:px-0 md:pr-4 lg:pr-0"}>
                            <p className={"uppercase text-lg font-formula-light tracking-widest mb-2"}>{short}</p>
                            <h1 className={"font-agrandir text-3xl"}>{title}</h1>
                            <div className={"my-4"}>
                                <a href={`https://open.spotify.com${artistLink}`} target="_blank" rel="noopener noreferrer">
                                    <Button label={"listen now on spotify"} type={"underline"} display={"inline"} />
                                </a>
                            </div>
                            <p className={"my-8"}>{description}</p>
                            <iframe 
                                title="spotify-follow"
                                src={`https://embed.spotify.com/follow/1/?${artistEmbed}&size=detail&theme=light`} 
                                width="300" 
                                height="56" 
                                scrolling="no" 
                                frameBorder="0" 
                                style={{border:"none", overflow:"hidden"}} 
                                allowtransparency="true">
                            </iframe>
                        </div>
                        <div className={"w-full px-4 md:px-0 md:w-1/2 mx-auto"}>
                            <iframe 
                                title="spotify-playlist"
                                src={`${playlistLink}`}
                                className={"float-left md:float-right"}
                                width="340" 
                                height="400" 
                                frameBorder="0" 
                                allowtransparency="true" 
                                allow="encrypted-media">
                            </iframe>
                        </div>
                    </div>
                    <div className={`${Styles.blueLine} mx-4 w-11/12 md:w-full md:mx-0`} />
                </div>
        )
    }
}

export default PlaylistItem
