import React from "react"
import { graphql } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"

import Layout from "../../components/layout/layout"
import PageTransition from "../../components/transition/page"
import PageHeader from "../../components/layout/page-header"
import PlaylistGroup from "../../components/music/playlist-group"
import SEO from "../../components/seo/seo"
import SliderCarousel from "../../components/carousel/slider"

class MusicPage extends React.Component {
  constructor(props) {
    super(props)
    this.pageTransition = React.createRef()
  }
  animate() {
    this.pageTransition.current.play()
  }
  render() {
    const { path, data } = this.props
    const locale = "en-AU"
    const { slider } = data
    return (
      <Layout locale={locale} theme="light" path={path} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
        <SEO
          title={data.seo.title}
          description={data.seo.description}
          keywords={data.seo.keywords && data.seo.keywords.keywords}
          image={data.seo.image.file.url}
        />
        <PageHeader theme={"coral"}>
          <SliderCarousel slides={slider.slides} locale={locale} />
        </PageHeader>
        <PlaylistGroup featured={data.featured.items} items={data.all.items} />
        <TransitionPortal>
          <PageTransition ref={this.pageTransition} />
        </TransitionPortal>
      </Layout>
    )
  }
}

export const query = graphql`
  query MusicPageQuery {
    seo: contentfulSeo(slug: {eq: "music"}) {
      title
      description
      keywords {
        keywords
      }
      image {
        file {
          url
        }
      }
    }
    slider: contentfulSlider(slug: { eq: "music" }, node_locale: { eq: "en-AU" }) {
      slides {
        ... on ContentfulSlide {
          title
          short
          image {
            file {
              url
            }
          }
          link
          cta
        }
      }
    }
    featured: allContentfulPlaylist(limit: 1, filter: { featured: { eq: true }, node_locale: { eq: "en-AU" } }) {
      items: edges {
        playlist: node {
          title
          slug
          description
          short
          artistLink
          artistEmbed
          playlistLink
        }
      }
    }
    all: allContentfulPlaylist(limit: 20, filter: { featured: { ne: true }, node_locale: { eq: "en-AU" } }) {
      items: edges {
        playlist: node {
          title
          slug
          description
          short
          artistLink
          artistEmbed
          playlistLink
        }
      }
    }
  }
`

export default MusicPage
